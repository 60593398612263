import React, { useEffect, useState } from "react";
import photo1 from "../static/images/photo1.png";
import photo2 from "../static/images/photo2.png";
import photo3 from "../static/images/photo3.png";
import photo4 from "../static/images/photo4.png";
import photo5 from "../static/images/photo5.png";
import { motion } from "framer-motion";
const ExamplePhoto = ({ starting_index, index }) => {
  const photos = [photo1, photo2, photo3, photo4, photo5];
  const [photoIndex, setPhotoIndex] = useState(starting_index);
  const [transitionStarted, setTransitionStarted] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setTransitionStarted(true);

      setTimeout(() => {
        setPhotoIndex((photoIndex) => (photoIndex + 1) % photos.length);
        setTransitionStarted(false);
      }, 1000 + index * 0.2);
    }, 6000);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: transitionStarted ? 0 : 1 }}
      transition={{
        duration: 0.5,
        delay: index * 0.2,
        ease: "easeInOut",
      }}
      className="w-full relative scrollbar-none overflow-hidden group rounded-2xl"
    >
      <img
        className="w-full group-hover:scale-110 transition-transform ease-in-out duration-500"
        src={photos[photoIndex]}
      />
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-10 group-hover:opacity-50 ease-in-out duration-500 transition-opacity"></div>
    </motion.div>
  );
};

export default ExamplePhoto;
