import React, { useState, useEffect } from "react";
import axios from "axios";
import api_url from "../constants";
import expand_arrow from "../static/images/expand-arrow.svg";

const ProductsCarousel = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productPages, setProductPages] = useState([]);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const images = document.querySelectorAll("img");
    images.forEach((image) => {
      image.addEventListener("dragstart", (e) => e.preventDefault());
      image.setAttribute("draggable", false);
    });
    axios
      .get(api_url + "/products/")
      .then((res) => {
        setProducts(res.data);
        const pages = [];
        for (let i = 0; i < Math.ceil(res.data.length / 6); i++) {
          pages.push(i);
        }
        setProductPages(pages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (products.length === 0) return;
    const carousel = document.getElementById("carousel-products");
    carousel.scrollLeft = (currentPage - 1) * carousel.clientWidth;
  }, [currentPage]);

  useEffect(() => {
    if (products.length === 0) return;

    const change_page = setInterval(() => {
      setCurrentPage(currentPage == productPages.length ? 1 : currentPage + 1);
    }, 4000);
    return () => clearInterval(change_page);
  });

  return products.length === 0 ? (
    <p className="w-full bg-black/20 py-8 mt-4 px-12 font-baskerville text-lg text-center font-semibold">
      Nu există produse disponibile momentan. Vă rugăm să reveniți mai târziu.
    </p>
  ) : (
    <div className=" mt-8 relative w-[75%] mx-auto">
      <div className="overflow-x-auto scrollbar-none" id="carousel-products">
        <button
          onClick={() => {
            setCurrentPage(
              currentPage == 1 ? productPages.length : currentPage - 1
            );
          }}
          className="transition-colors ease-in-out duration-300 hover:bg-black/30 p-3 absolute top-1/2 left-0 trnslate-y-[-50%] translate-x-[-150%] w-fit rounded-full bg-black/20"
        >
          <img className="w-6 sm:w-3 opacity-40 " src={expand_arrow} />
        </button>
        <button
          onClick={() => {
            setCurrentPage(
              currentPage == productPages.length ? 1 : currentPage + 1
            );
          }}
          className="transition-colors ease-in-out duration-300 hover:bg-black/30 p-3 absolute top-1/2 right-0 trnslate-y-[-50%] translate-x-[150%] w-fit rounded-full bg-black/20"
        >
          <img
            className="w-6 sm:w-3 opacity-40 rotate-180"
            src={expand_arrow}
          />
        </button>
        <div
          className={"flex flex-nowrap overflow-auto "}
          style={{
            width: productPages.length * 100 + "%",
          }}
        >
          {productPages.map((productPage) => (
            <div className={"grid grid-cols-3 sm:grid-cols-2 gap-8 w-full"}>
              {products
                .slice(productPage * 6, productPage * 6 + 6)
                .map((product) => (
                  <div className="flex flex-col w-fit mx-auto">
                    <img
                      className=" h-56 w-56 sm:w-32 sm:h-32 md:w-36 md:h-36 object-cover rounded-2xl mx-auto"
                      src={"data:image/png;base64," + product.image}
                    />
                    <h3 className="w-fit md:text-sm mx-auto mt-1 text-center">
                      {product.name}
                    </h3>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductsCarousel;
