import React, { useEffect, useState, useMemo } from "react";
import header_background from "../static/images/header-background.JPG";
import { motion } from "framer-motion";
import address from "../static/images/address.svg";
import phone from "../static/images/phone.svg";
import clock from "../static/images/clock.svg";
import shopping_cart from "../static/images/shopping-cart.svg";
import ExamplePhoto from "../components/ExamplePhoto";
import other_service_background from "../static/images/other-service-background.png";
import axios from "axios";
import api_url from "../constants";
import ProductsCarousel from "../components/ProductsCarousel";
import check from "../static/images/check.svg";
import confirmation from "../static/images/confirmation.svg";
import { LineWave } from "react-loader-spinner";
import { Helmet } from "react-helmet";
import mero_logo from "../static/images/mero-logo.svg";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

const newServices = [
  { name: "Tuns spălat aranjat", price: 75, duration: 40 },
  { name: "Tuns păr mediu / lung", price: 100, duration: 40 },
  { name: "Tuns spălat aranjat barba", price: 115, duration: 40 },
  { name: "Tuns zero și spălat", price: 40, duration: 40 },
  { name: "Tuns din mașină (1,2 numere ) și spălat", price: 50, duration: 40 },
  { name: "Tuns copii ( 6 ani)", price: 60, duration: 40 },
  { name: "Întreținere barbă", price: 35, duration: 20 },
  { name: "Spălat aranjat", price: 35, duration: 20 },
  { name: "Spălat", price: 10, duration: 15 },
  { name: "Diagnoză tricologie", price: 35, duration: 20 },
  { name: "Tratament capilar", price: 75, duration: 40 },
  {
    name: "Vip : tuns spălat aranjat barbă prosop cald , mască detoxifiantă",
    price: 170,
    duration: 40,
  },
  { name: "Pensat", price: 30, duration: 20 },
  { name: "Epilat pomeți", price: 30, duration: 10 },
  { name: "Epilat nas", price: 15, duration: 10 },
  { name: "Epilat urechi", price: 15, duration: 10 },
];

const features = [
  {
    name: "Produse Premium",
    description:
      "Utilizăm numai produse premium , recunoscute pentru rezultatele lor excepționale. De la produse de îngrijire a pielii la cele de coafat, ne asigurăm că vei experimenta doar calitatea superioară în timpul fiecărui serviciu.",
  },
  {
    name: "Specialiști în Frizerie",
    description:
      "Echipa noastră este formată din specialiști cu experiență, pasionați de arta frizeriei. De la cele mai proaspete tendințe, la tăieturi clasice remarcabile, diversitatea abilităților lor va conferi un strop de eleganță fiecărui serviciu.",
  },
  {
    name: "Recomandări Personalizate",
    description:
      "Nu suntem doar experți în frumusețe, ci și consilieri de încredere. Te vom ghida în alegerea ideală pentru tine, împărtășindu-ți sfaturi personalizate pentru a-ți pune în evidență caracteristicile și stilul propriu.",
  },
  {
    name: "Spațiu Prietenos cu Copiii",
    description:
      "Ne mândrim cu faptul că suntem prietenoși cu copiii. Avem jucării și personal grijuliu care se va asigura că și cei mici se simt bineveniți și fericiți.",
  },
];

// const hair_services = [
//   {
//     name: "Tuns, spălat, aranjat (include diagnoza & produse speciale tipului de păr)",
//     duration: 40,
//     price: 60,
//   },
//   {
//     name: "Tuns, spălat, aranjat barbă (include diagnoza & produse speciale tipului de păr)",
//     duration: 40,
//     price: 100,
//   },
//   {
//     name: "Tuns zero și spălat",
//     duration: 30,
//     price: 40,
//   },
//   {
//     name: "Tuns din mașină (1.2 numere) și spălat",
//     duration: 35,
//     price: 50,
//   },
//   {
//     name: "Întreținere barbă (include șampon, aftershave & balsam",
//     duration: 25,
//     price: 50,
//   },
//   {
//     name: "Diagnoză tricologie (consultație microcameră, consultăm posbilele probleme ale scalpului)",
//     duration: 25,
//     price: 45,
//   },
//   {
//     name: "Tratament capilar (include tratament scalp, spălat & aranjat)",
//     duration: 40,
//     price: 75,
//   },
//   {
//     name: "Aranjat evenimente (include spălat, masaj capilar & styling premium)",
//     duration: 30,
//     price: 45,
//   },
//   {
//     name: "Masaj Capilar",
//     duration: 20,
//     price: 35,
//   },
// ];

// const face_care = [
//   {
//     name: "Masaj Facial",
//     duration: 20,
//     price: 35,
//   },
//   {
//     name: "Tratament facial cu argilă pentru tenul gras (efect de calmare, matifiere si diminuare a porilor)",
//     duration: 40,
//     price: 95,
//   },
//   {
//     name: "Tratament black mask (ajută la eliminarea punctelor negre & lăsând pielea vizibil mai luminoasă)",
//     duration: 40,
//     price: 75,
//   },
//   {
//     name: "Mască detox (elimină celulele moarte și particulele nocive provenite din mediu; previne depunerea de reziduri și prad din atmosferă",
//     duration: 40,
//     price: 75,
//   },
// ];

// const packages = [
//   {
//     name: "Pachet Silver: tuns, spălat, masaj capilar & aranjat",
//     duration: 40,
//     price: 86,
//   },
//   {
//     name: "Pachet Gold: tuns, aranjat barbă, pensat & masaj facial",
//     duration: 90,
//     price: 140,
//   },
//   {
//     name: "Pachet VIP: tuns, aranjat barbă, pensat, tratament detox, tratament capilar, masaj capilar & masaj facial",
//     duration: 140,
//     price: 289,
//   },
// ];

const Home = () => {
  const [nextDays, setNextDays] = useState([]);
  const [nextHours, setNextHours] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedBarber, setSelectedBarber] = useState(null);
  const [toggleSelectService, setToggleSelectService] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [possibleBarbers, setPossibleBarbers] = useState([]);
  const [firstSearched, setFirstSearched] = useState(true);
  const [appointmentSubmitted, setAppointmentSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDuaTkVh8-ue6vfJSYHUbaVYIXGxjiao7g",
  });

  const center = useMemo(() => ({ lat: 47.6469805, lng: 23.536237 }), []);
  useEffect(() => {
    if (selectedDay && selectedHour && selectedService) {
      const [day, month, year] = selectedDay.split(".");
      const date_string = year + "-" + month + "-" + day;
      axios
        .get(api_url + "/appointments/possible/", {
          params: {
            date: date_string,
            time: selectedHour,
            service: selectedService.id,
          },
        })
        .then((res) => {
          console.log(res.data);
          setPossibleBarbers(res.data);
          setFirstSearched(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDay, selectedHour, selectedService]);

  useEffect(() => {
    if (appointmentSubmitted) return;
    const select_service = document.getElementById("select-service");
    document.addEventListener("click", (e) => {
      if (select_service && !select_service.contains(e.target)) {
        setToggleSelectService(false);
      }
    });
    axios
      .get(api_url + "/services/")
      .then((res) => {
        setServices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function generateTimeBlocks(start, end, intervalMinutes) {
    const time_blocks = [];
    let currentTime = new Date(start);
    while (currentTime <= end) {
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      time_blocks.push(`${hours}:${minutes}`);

      currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
    }
    return time_blocks;
  }

  useEffect(() => {
    const today = new Date();
    const n_days = [];
    for (let i = 1; i < 30; i++) {
      let date = new Date(today);
      //check if the day is last day of the month
      if (
        date.getDate() + i >
        new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
      ) {
        date = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        for (let j = 0; j < 30; j++) {
          const day = date.getDate() + j;
          const formatted_day = day.toString().padStart(2, "0");
          const month = date.getMonth() + 1;
          const formatted_month = month.toString().padStart(2, "0");
          const year = date.getFullYear();
          const formatted_date =
            formatted_day + "." + formatted_month + "." + year;
          n_days.push(formatted_date);
          if (n_days.length == 30) {
            break;
          }
        }
        break;
      }
      const day = date.getDate() + i;
      const formatted_day = day.toString().padStart(2, "0");
      const month = date.getMonth() + 1;
      const formatted_month = month.toString().padStart(2, "0");
      const year = date.getFullYear();
      const formatted_date = formatted_day + "." + formatted_month + "." + year;
      n_days.push(formatted_date);
    }
    setNextDays(n_days);

    //set the hours from 09:00 to 17:00 divided in blocks of 15 minutes
    const start = new Date();
    start.setHours(9);
    start.setMinutes(0);
    const end = new Date();
    end.setHours(20);
    end.setMinutes(30);
    const hours = generateTimeBlocks(start, end, 15);
    setNextHours(hours);
  }, []);

  function CreateAppointment(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (!selectedDay || !selectedHour || !selectedBarber || !selectedService) {
      alert("Selectează toate câmpurile");
      return;
    }
    if (
      formData.get("client_name") == "" ||
      formData.get("client_phone") == "" ||
      formData.get("client_email") == ""
    ) {
      alert("Completează toate câmpurile");
      return;
    }
    const [day, month, year] = selectedDay.split(".");
    const date_string = year + "-" + month + "-" + day;
    formData.append("date", date_string);
    formData.append("time", selectedHour);
    formData.append("barber", selectedBarber.id);
    formData.append("service", selectedService.id);
    setIsLoading(true);
    axios
      .post(api_url + "/appointment/", formData)
      .then((res) => {
        console.log(res.data);
        setAppointmentSubmitted(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zen Cut Barbershop</title>
        <meta
          name="description"
          content="Zen Cut Barbershop este un salon de frizerie și îngrijire a bărbaților din Baia Mare. Aici vei găsi servicii de tuns, spălat, aranjat, vopsit barbă, bărbierit turcesc, masaj facial, masaj capilar, tratamente capilare și multe altele."
        />
      </Helmet>
      <section>
        <div className="w-full min-h-[80vh] h-[80vh] relative overflow-hidden scrollbar-none">
          <motion.img
            initial={{ scale: 1 }}
            animate={{ scale: 1.1 }}
            transition={{ duration: 3, type: "spring" }}
            src={header_background}
            className="object-cover h-full w-screen"
          />
          <div className="bg-black/50 top-0 bottom-0 left-0 right-0 w-full h-full absolute backdrop-blur-xs"></div>
          <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col">
            <nav className="px-8 py-4 w-fit sm:block sm:mx-auto">
              <h1 className="font-montecarlo text-8xl text-white drop-shadow-md w-fit sm:w-full sm:mx-auto sm:text-center">
                Zen Cut
              </h1>
              <p className="font-baskerville text-white text-xss drop-shadow-md w-fit ml-auto ">
                BARBER SHOP BY CAMELIA MARIES
              </p>
            </nav>
          </div>
        </div>
        <div className="font-baskerville lg:w-4/5 sm:flex-col md:grid md:gap-y-4 md:w-4/5 md:grid-cols-2 md:justify-between justify-evenly flex w-2/3 mx-auto drop-shadow-lg rounded-2xl translate-y-[-50%] sm:translate-y-[-25%] p-4 mt-auto bg-white">
          <a href="#map" className="w-fit text-center mx-auto">
            <img src={address} className="w-20 md:w-16 p-2 mx-auto" />
            <p className="mt-3 font-bold">Adresă</p>
            <p className="text-sm">Value Center, Baia Mare</p>
          </a>
          <a
            href="tel:+40757846390"
            className="w-fit md:order-3 sm:order-3 text-center mx-auto"
          >
            <img src={phone} className="w-20 p-3 md:w-16 mx-auto" />
            <p className="mt-2 font-bold">Contact</p>
            <p className="text-sm">+40 757 846 390</p>
          </a>
          <a
            href="https://mero.ro/p/zencutbarbershop?absp=search_autocomplete"
            className="w-fit block md:order-5 sm:mt-8 sm:order-5 md:col-span-2 md:mt-4 text-center mx-auto group cursor-pointer"
          >
            <img
              src={mero_logo}
              className="w-28 md:w-28 group-hover:scale-105 shadow-sm ease-in-out duration-300 group-hover:shadow-lg transition-all mx-auto"
            />
            <p className="font-bold text-2xl mt-2">MERO</p>
          </a>
          <div className="w-fit text-center mx-auto">
            <img src={clock} className="w-20 md:w-16 p-3 mx-auto" />
            <p className="mt-2 font-bold">Orar</p>
            <p className="text-sm">Zilnic 09:00-21:00</p>
          </div>
          <a href="#products" className="w-fit text-center mx-auto">
            <img src={shopping_cart} className="w-20 md:w-16 p-2 mx-auto" />
            <p className="mt-2 font-bold">Magazin</p>
            <p className="text-sm">Barber Shop</p>
          </a>
        </div>
      </section>
      <section>
        <h2 className="header">Servicii</h2>
        <p className="font-baskerville font-semibold text-center mx-auto mt-4 w-2/3 sm:w-[95%] lg:w-4/5 md:w-[90%]">
          De la tunsori impecabile la tratamente de lux pentru bărbați,
          serviciile noastre definesc eleganța masculină.
        </p>
        <div className="flex flex-col gap-y-6 sm:gap-y-8 w-2/3 lg:w-4/5 md:w-[90%] sm:w-[95%] mt-8 mx-auto">
          {newServices.map((service) => (
            <div className="grid gap-x-8 grid-cols-[1fr,14rem] sm:grid-cols-1 font-baskerville">
              <p className="text-lg ">{service.name}</p>
              <p className="text-lg grid grid-cols-2  h-fit">
                <span className="border-r-2 border-r-solid border-r-black">
                  {service.duration} min
                </span>
                <span className="text-right">{service.price} RON</span>
              </p>
            </div>
          ))}
        </div>
        {/* <h2 className="header mt-16">Îngrijirea Tenului</h2>
        <p className="font-baskerville font-semibold text-center mx-auto mt-4 w-2/3 lg:w-4/5 md:w-[90%] sm:w-[95%]">
          Redefinim luxul prin atenția noastră la detalii. Pentru o îngrijire a
          pielii care să reflecte eleganța ta.
        </p>
        <div className="flex flex-col gap-y-6 w-2/3 lg:w-4/5 md:w-[90%] sm:w-[95%] sm:gap-y-8 mt-8 mx-auto">
          {face_care.map((service) => (
            <div className="grid gap-x-8 grid-cols-[1fr,14rem] sm:grid-cols-1 font-baskerville">
              <p className="text-lg ">{service.name}</p>
              <p className="text-lg grid grid-cols-2  h-fit">
                <span className="border-r-2 border-r-solid border-r-black">
                  {service.duration} min
                </span>
                <span className="text-right">{service.price} RON</span>
              </p>
            </div>
          ))}
        </div>
        <h2 className="header mt-16">Pachete</h2>
        <p className="w-2/3 lg:w-4/5  font-semibold text-center md:w-[90%] font-baskerville sm:w-[95%] mx-auto mt-4">
          Descoperă pachetele noastre meticulos create, unde fiecare tratament
          se îmbină în armonie pentru a oferi o experiență completă de îngrijire
          și stil.
        </p> */}
        {/* <div className="flex flex-col gap-y-6 w-2/3 lg:w-4/5 md:w-[90%] mt-8 mx-auto sm:gap-y-8 sm:w-[95%]">
          {packages.map((service) => (
            <div className="grid gap-x-8 grid-cols-[1fr,14rem] sm:grid-cols-1 font-baskerville">
              <p className="text-lg ">{service.name}</p>
              <p className="text-lg grid grid-cols-2  h-fit">
                <span className="border-r-2 border-r-solid border-r-black">
                  {service.duration} min
                </span>
                <span className="text-right">{service.price} RON</span>
              </p>
            </div>
          ))}
        </div> */}
      </section>
      <section>
        <h2 className="font-montecarlo text-6xl sm:text-5xl text-center mx-auto w-fit mt-16">
          De ce să alegi Zen Cut?
        </h2>
        <div className="font-baskerville w-3/4 lg:w-4/5 md:w-[90%] sm:w-[95%] gap-x-10 mx-auto grid grid-cols-2 sm:grid-cols-1 gap-y-8 mt-10">
          {features.map((feature) => (
            <div>
              <h3 className="text-lg w-fit mx-auto font-semibold">
                {feature.name}
              </h3>
              <p className="mx-auto mt-2">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="grid grid-cols-2 sm:w-[95%] sm:grid-cols-1 gap-8 mt-12 w-2/3 lg:w-4/5 md:w-[90%] mx-auto">
        <ExamplePhoto starting_index={0} index={0} />
        <ExamplePhoto starting_index={3} index={1} />
        <ExamplePhoto starting_index={2} index={2} />
        <ExamplePhoto starting_index={1} index={3} />
      </section>
      <section className="w-[70%] lg:w-4/5 md:w-[85%] sm:w-[95%] mx-auto mt-16">
        <h2 className="font-montecarlo text-6xl mx-auto w-fit sm:text-5xl">
          Magazin
        </h2>
        <p className="font-baskerville font-semibold w-full text-center mx-auto mt-4">
          Explorați colecția noastră Davines de produse premium pentru
          îngrijirea părului. Găsiți șampoane, balsamuri și tratamente
          inovatoare, create pentru a vă îmbunătăți frumusețea naturală.
          Descoperiți calitatea și eleganța în fiecare produs la cele mai bune
          prețuri.
        </p>
        <a id="products" />
        <ProductsCarousel />
      </section>
      {/* <section className="relative mt-12 min-h-[50rem] h-fit w-full">
        <img
          src={header_background}
          className="absolute  top-0 left-0 right-0 bottom-0 object-cover h-full w-full"
        />
        <div className="bg-black/50 top-0 bottom-0 left-0 right-0 w-full h-full absolute backdrop-blur-xs"></div>
        {isLoading ? (
          <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
            <LineWave
              height="150"
              width="150"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : appointmentSubmitted ? (
          <div className="absolute bg-black/50 backdrop-blur-xs p-8 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
            <img src={confirmation} className="w-20 h-20 mx-auto" />
            <h2 className="text-2xl text-white font-semibold w-fit mt-12 mx-auto">
              Programarea a fost trimisă cu succes!
            </h2>
            <p className="text-white text-center mt-4">
              Ați primit un email de confirmare. Acesta conține toate detaliile
              programării dumneavoastră.
            </p>
            <p className="text-white text-center mt-4">
              Vă așteptăm cu drag la Zen Cut!
            </p>
          </div>
        ) : (
          <form
            onSubmit={CreateAppointment}
            className="w-2/3 sm:w-[95%] lg:w-4/5 md:w-[90%] bg-black/50 relative top-0 h-full font-baskerville left-1/2 translate-x-[-50%]"
          >
            <h2 className="text-2xl text-white font-semibold w-fit pt-12 mx-auto">
              Programează-te acum
            </h2>
            <div className="px-10 py-10 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-10">
              <input
                name="client_name"
                placeholder="Nume"
                className="appointment-input"
              />
              <input
                name="client_phone"
                placeholder="Telefon"
                className="appointment-input"
              />
              <input
                name="client_email"
                placeholder="Email"
                className="appointment-input"
              />
              <input
                name="client_comment"
                placeholder="Comentariu"
                className="appointment-input text-base "
              ></input>
              <div className="relative col-span-2 sm:col-span-1">
                <div
                  id="select-service"
                  onClick={() => setToggleSelectService(!toggleSelectService)}
                  className={
                    "cursor-pointer appointment-input" +
                    (toggleSelectService ? " border-t-white px-4" : "")
                  }
                >
                  {selectedService ? (
                    <span className="flex justify-between">
                      {" "}
                      {selectedService.name}{" "}
                      <span>
                        {selectedService.price + " RON"} |{" "}
                        {selectedService.duration + " min"}
                      </span>
                    </span>
                  ) : (
                    "Alege Serviciul"
                  )}
                </div>
                <motion.div
                  initial={{ y: "95%", height: 0 }}
                  animate={{
                    y: toggleSelectService ? "100%" : "95%",
                    height: toggleSelectService ? "auto" : 0,
                  }}
                  className={
                    "backdrop-blur overflow-y-auto scrollbar-none bg-black/60 w-full absolute bottom-0 translate-y-[102%] text-white"
                  }
                >
                  <ul className="max-h-[15rem] overflow-y-auto flex flex-col gap-y-3">
                    {services.map((service) => (
                      <li
                        onClick={() => setSelectedService(service)}
                        value={service.id}
                        className="cursor-pointer hover:bg-black px-2 transition-all py-2 ease-in-out duration-300"
                      >
                        {service.name}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              </div>
            </div>
            <div className="px-10 py-10 grid sm:grid-cols-1 md:gap-y-8 grid-cols-3 md:grid-cols-2 gap-x-10 text-white">
              <div>
                <h3 className="pb-1 text-gray-300 text-xl border-2 border-solid border-transparent border-b-white text-center ">
                  Alege Data
                </h3>
                <div className="font-thin gap-y-6 scrollbar-none mt-6 overflow-y-auto h-80 w-full mx-auto text-xl flex flex-col">
                  {nextDays.map((day) => (
                    <div
                      onClick={() => setSelectedDay(day)}
                      className={
                        "flex justify-between cursor-pointer hover:bg-black/50 py-2 hover:px-2 transition-all ease-in-out duration-300" +
                        (selectedDay == day ? " px-2 bg-black/50" : "")
                      }
                    >
                      {day}
                      <motion.img
                        initial={{ opacity: 0, x: -10 }}
                        animate={{
                          opacity: selectedDay == day ? 1 : 0,
                          x: selectedDay == day ? 0 : -10,
                        }}
                        src={check}
                        className="w-4"
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h3 className="pb-1 text-gray-300 text-xl border-2 border-solid border-transparent border-b-white text-center ">
                  Alege Ora
                </h3>
                <div className="font-thin gap-y-6 scrollbar-none mt-6 overflow-y-auto h-80 w-full mx-auto text-xl flex flex-col">
                  {nextHours.map((hour) => (
                    <div
                      onClick={() => setSelectedHour(hour)}
                      className={
                        "flex justify-between cursor-pointer hover:bg-black/50 py-2 hover:px-2 transition-all ease-in-out duration-300" +
                        (selectedHour == hour ? " px-2 bg-black/50" : "")
                      }
                    >
                      {hour}
                      <motion.img
                        initial={{ opacity: 0, x: -10 }}
                        animate={{
                          opacity: selectedHour == hour ? 1 : 0,
                          x: selectedHour == hour ? 0 : -10,
                        }}
                        src={check}
                        className="w-4"
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="md:col-span-2">
                <h3 className="pb-1 text-gray-300  text-xl border-2 border-solid border-transparent border-b-white text-center ">
                  Alege Frizerul
                </h3>
                <div className="font-thin gap-y-6 scrollbar-none mt-6 overflow-y-auto h-80 w-full mx-auto text-xl flex flex-col">
                  {possibleBarbers.length == 0 && firstSearched ? (
                    <div className="text-sm font-baskerville text-center text-gray-300">
                      Alege serviciul, data și ora pentru a selecta frizerul...
                    </div>
                  ) : possibleBarbers.length == 0 ? (
                    <div className="text-sm font-baskerville text-center text-gray-300">
                      Nu am găsit niciun frizer disponibil. Selectează altă dată
                      sau oră...
                    </div>
                  ) : (
                    possibleBarbers.map((barber) => (
                      <div
                        onClick={() => setSelectedBarber(barber)}
                        className={
                          "w-full flex flex-col gap-y-4 justify-center items-center mx-auto cursor-pointer hover:bg-black/50 py-2 hover:py-4 transition-all ease-in-out duration-300" +
                          (selectedBarber == barber ? " py-4 bg-black/50" : "")
                        }
                      >
                        <img
                          src={"data:image/png;base64," + barber.image}
                          className="w-32 h-32 rounded-full"
                        />
                        <p className="flex gap-x-3 items-center">
                          {barber.name}{" "}
                          <motion.img
                            initial={{ opacity: 0, x: -10, width: 0 }}
                            animate={{
                              opacity: selectedBarber == barber ? 1 : 0,
                              x: selectedBarber == barber ? 0 : -10,
                              width: selectedBarber == barber ? "1rem" : 0,
                            }}
                            src={check}
                            className="h-4"
                          />
                        </p>
                      </div>
                    ))
                  )}
                </div>
                <button className="md:mt-12 w-full flex text-center ml-auto mt-6 justify-center font-medium bg-gray-300 text-black py-2 rounded-2xl">
                  Programează-te
                </button>
              </div>
            </div>
          </form>
        )}
      </section> */}
      <section className="w-2/3 lg:w-4/5 md:w-[90%] mx-auto mt-16 sm:w-[95%]">
        <h2 className="font-montecarlo text-6xl mx-auto w-fit sm:text-5xl">
          Alte Servicii
        </h2>
        <div className="mt-12 sm:mt-8 relative rounded-2xl">
          <img
            className="h-96 lg:h-[30rem] sm:h-[60rem] md:h-[60rem] w-full object-cover rounded-2xl"
            src={other_service_background}
          />
          <div className="rounded-2xl absolute top-0 bottom-0 left-0 right-0 bg-black/50 backdrop-blur-xs"></div>
          <div className=" bg-black/50 flex flex-col gap-y-4 font-light absolute top-0 text-gray-300 w-[90%] sm:px-2 text-lg sm:text-base px-10 py-4 rounded-2xl mt-20 font-baskerville left-1/2 translate-x-[-50%]">
            <p>
              Dar nu ne oprim aici. Pentru a face momentele speciale cu adevărat
              memorabile, oferim ceva unic: închirierea unui SUV Mercedes de
              lux, pentru evenimente ce necesită o notă de eleganță și
              rafinament în plus. Puteți ajunge în stil la nuntă, botez,
              petrecere sau oricare alt eveniment important din viața
              dumneavoastră.
            </p>{" "}
            <p>
              Noi nu doar creăm stil, ci și transformăm experiențele.
              Imaginați-vă să fiți însoțiți de luxul Mercedesului nostru, cu
              serviciile impecabile pe care le oferim. Vă invităm să
              experimentați servicii de înfrumusețare și eleganță, într-un cadru
              luxos și atent la detalii.
            </p>
          </div>
        </div>
      </section>
      <a id="map" />
      <section className="mt-16">
        <div className="w-2/3 lg:w-4/5 md:w-[90%] mx-auto h-96 rounded-2xl sm:w-[95%]">
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={center}
              zoom={16}
            >
              <MarkerF position={center} label="Zen Cut Barbershop" />
            </GoogleMap>
          )}
        </div>
      </section>
      <div className="flex justify-between mt-20 px-8 text-sm py-1 sm:flex-col gap-y-2 sm:px-2">
        <div>© 2023 Zen Cut. Toate drepturile rezervate.</div>
        <a href="https://www.scriptify.ro">made by scriptify</a>
      </div>
    </div>
  );
};

export default Home;
