import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import useMediaQuery from "../hooks/useMediaQuery";
const ImageCropModal = ({
  setResultFile,
  setResultImage,
  file,
  showModal,
  exitModal,
}) => {
  const cropperRef = useRef(null);
  const isSM = useMediaQuery("(max-width: 640px)");

  function cropImage() {
    const cropper = cropperRef.current.cropper;
    const croppedData = cropper.getData();
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = croppedData.width;
      canvas.height = croppedData.height;
      ctx.drawImage(
        image,
        croppedData.x,
        croppedData.y,
        croppedData.width,
        croppedData.height,
        0,
        0,
        croppedData.width,
        croppedData.height
      );
      canvas.toBlob((blob) => {
        setResultFile(blob);
        setResultImage(URL.createObjectURL(blob));
        exitModal();
      });
    };
  }

  return (
    <motion.div
      initial={{ opacity: 0, display: "none" }}
      animate={{
        opacity: showModal ? 1 : 0,
        display: showModal ? "block" : "none",
        transition: {
          duration: 0.3,
          display: { delay: showModal ? 0 : 0.3 },
        },
      }}
      exit={{ opacity: 0, display: "none" }}
      className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-20"
    >
      <motion.div
        initial={{ opacity: 0, display: "none" }}
        animate={{ opacity: 1, display: "block" }}
        exit={{ opacity: 0, display: "none" }}
        onClick={exitModal}
        className="w-full absolute top-0 left-0 right-0 bottom-0 bg-black/30"
      ></motion.div>
      <motion.div>
        <motion.div
          initial={{ opacity: 0, x: "-50%", y: "-55%" }}
          animate={{
            opacity: 1,
            x: "-50%",
            y: showModal ? "-50%" : "-45%",
            transition: { duration: 0.3 },
          }}
          exit={{ opacity: 0, y: 100 }}
          className="bg-primary px-10 py-12 z-10 absolute top-1/2 left-1/2"
        >
          <Cropper
            src={file && URL.createObjectURL(file)}
            style={{
              height: 450,
              width: isSM ? "100%" : 450,
              margin: "0 auto",
            }}
            initialAspectRatio={1}
            aspectRatio={1}
            guides={false}
            center={true}
            zoomable={false}
            viewMode={1}
            ref={cropperRef}
          />
          <div className="flex justify-between mt-10 gap-x-8">
            <button
              type="button"
              onClick={exitModal}
              className="primary-button bg-red-700 rounded-none px-12 py-1 font-semibold"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={cropImage}
              className="primary-button bg-green-600 rounded-none px-12 py-1 font-semibold"
            >
              Accept
            </button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ImageCropModal;
