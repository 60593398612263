import React, { useEffect, useState } from "react";
import axios from "axios";
import api_url from "../constants";

const AdminProductCard = ({ products, setProducts, product }) => {
  const [name, setName] = useState(product.name);
  useEffect(() => {
    setName(product.name);
  }, [product]);
  function DeleteProduct() {
    axios
      .delete(api_url + "/product/" + product.id + "/")
      .then((res) => {
        console.log(res);
        setProducts(products.filter((item) => item.id !== product.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function ModifyProduct(e) {
    e.preventDefault();
    axios
      .put(api_url + "/product/" + product.id + "/", { name: name })
      .then((res) => {
        console.log(res);
        setProducts(
          products.map((item) => {
            if (item.id === product.id) {
              return { ...item, name: name };
            }
            return item;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <form
      onSubmit={ModifyProduct}
      key={product.id}
      className="flex flex-col gap-y-2"
    >
      <div className="relative group primary-border">
        <img
          src={"data:image/png;base64," + product.image}
          className="w-full h-full object-cover"
        />
        <div className="absolute group-hover:bg-black/25 top-0 left-0 right-0 bottom-0 transition-colors ease-in-out duration-300"></div>
        <button
          type="button"
          onClick={DeleteProduct}
          className="alert-button text-sm absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] delay-100 transition-opacity ease-in-out duration-500 opacity-0 group-hover:opacity-100 px-10 py-1 font-semibold"
        >
          DELETE
        </button>
      </div>
      <input
        className="admin-input w-full"
        value={name}
        onChange={(e) => setName(e.target.value)}
        name="name"
        placeholder="Product Name"
      />
      {name != product.name && (
        <button
          type="submit"
          className="primary-button px-10 text-sm py-1 w-fit ml-auto font-semibold"
        >
          UPDATE
        </button>
      )}
    </form>
  );
};

export default AdminProductCard;
