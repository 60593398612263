import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import change from "../static/images/change.svg";
import ImageCropModal from "../components/ImageCropModal";
import axios from "axios";
import api_url from "../constants";
import AdminProductCard from "../components/AdminProductCard";
import { Helmet } from "react-helmet";
import { LineWave } from "react-loader-spinner";
const AdminProducts = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const [toggleForm, setToggleForm] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "/products/")
      .then((res) => {
        setProducts(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  function CreateProduct(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("image", resultFile);
    formData.append("image_type", fileType.split("/")[1]);
    axios
      .post(api_url + "/product/", formData)
      .then((res) => {
        console.log(res);
        setToggleForm(false);
        e.target.reset();
        setResultImage(null);
        setResultFile(null);
        setFile(null);
        setProducts([...products, res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return isLoading ? (
    <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
      <LineWave
        height="150"
        width="150"
        radius="9"
        color="#242222"
        ariaLabel="three-dots-loading"
      />
    </div>
  ) : (
    <div>
      <Helmet>
        <title>Admin Panel - Produse</title>
      </Helmet>
      <motion.button
        initial={{ opacity: 100, height: "auto" }}
        animate={{
          opacity: toggleForm ? 0 : 100,
          height: toggleForm ? 0 : "auto",
        }}
        transition={{ duration: 0.3 }}
        className={
          "primary-button  font-medium !rounded-none " +
          (toggleForm ? "cursor-default" : "cursor-pointer")
        }
        onClick={() => setToggleForm(true)}
      >
        <span className="px-8 py-1 block">Adaugă produs</span>
      </motion.button>
      <motion.form
        onSubmit={CreateProduct}
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: toggleForm ? "auto" : 0,
          opacity: toggleForm ? 1 : 0,
        }}
        exit={{ height: 0, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className={"w-full mt-4 overflow-auto scrollbar-none"}
      >
        <h2>Formular de adăugare produse</h2>
        <div className="grid sm:grid-cols-1 sm:gap-y-4 sm:w-full grid-cols-[10rem,1fr] mt-1 gap-x-4 w-1/2 lg:w-3/4 md:w-full">
          {resultImage ? (
            <label
              htmlFor="product-graphics"
              className="w-40 h-40 sm:mx-auto primary-border group relative cursor-pointer"
            >
              <img src={resultImage} className="w-full" />
              <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
              <img
                src={change}
                className="w-10 absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150"
              />
              <input
                ref={file}
                onChange={selectFile}
                id="product-graphics"
                type="file"
                className="hidden"
              />
            </label>
          ) : (
            <label
              htmlFor="product-graphics"
              className="cursor-pointer sm:mx-auto group w-40 h-40 bg-transparent primary-border flex items-center text-center justify-center"
            >
              <div className="w-fit mx-auto group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition-transform text-4xl font-regular scale-150 text-black">
                +
              </div>
              <input
                ref={file}
                onChange={selectFile}
                id="product-graphics"
                type="file"
                className="hidden"
              />
            </label>
          )}
          <div className="flex flex-col gap-y-4">
            <input
              name="name"
              placeholder="Product Name"
              className="admin-input w-full"
            />
            <div className="w-fit sm:flex-col sm:gap-y-2 ml-auto gap-x-4 flex">
              <button
                type={"button"}
                onClick={() => setToggleForm(false)}
                className="alert-button px-10 py-1 font-semibold"
              >
                Cancel
              </button>
              <button
                className="primary-button rounded-none px-10 py-1 font-semibold"
                type="submit"
              >
                New Product
              </button>
            </div>
          </div>
        </div>
      </motion.form>
      <ImageCropModal
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 lg:grid-cols-3 md:grid-cols-2 gap-y-8 mt-8">
        {products.map((product) => (
          <AdminProductCard
            products={products}
            setProducts={setProducts}
            key={product.id}
            product={product}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminProducts;
