import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import box from "../static/images/box.svg";
import clock_light from "../static/images/clock-light.svg";
import calendar from "../static/images/calendar.svg";
import burger from "../static/images/burger.svg";
import api_url from "../constants";
import axios from "axios";
import exit from "../static/images/exit.svg";

const AdminLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = location.pathname.split("/")[2];
  const [toggleNavBar, setToggleNavBar] = useState(false);
  const [code, setCode] = useState("");
  const [accessGranted, setAccessGranted] = useState(false);

  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("/admin/products");
    }
    const code = localStorage.getItem("code");
    axios
      .post(api_url + "/verify-code/", { code })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setAccessGranted(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getAccess(e) {
    e.preventDefault();
    const code = e.target.code.value;
    axios
      .post(api_url + "/verify-code/", { code })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setAccessGranted(true);
          localStorage.setItem("code", code);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return !accessGranted ? (
    <form
      onSubmit={getAccess}
      className=" absolute top-1/2 left-1/2 gap-y-4 translate-x-[-50%] translate-y-[-50%] w-80 sm:w-full px-4 flex flex-col"
    >
      <input
        name="code"
        className="admin-input"
        placeholder="Parola de Admin"
        onChange={(e) => setCode(e.target.value)}
        value={code}
      />
      <button className="primary-button py-1 text-sm font-semibold">
        Accesează Admin Panel
      </button>
    </form>
  ) : (
    <div className="flex bg-primary">
      <div
        className={
          "bg-dark sm:py-4 py-8 min-h-[100vh]" +
          (toggleNavBar
            ? "sm:w-full sm:absolute z-50 top-0 bottom-0 left-0 right-0"
            : " md:hidden sm:hidden w-56")
        }
      >
        <img
          onClick={() => setToggleNavBar(!toggleNavBar)}
          src={burger}
          className="w-8 cursor-pointer mr-4 my-2 hidden ml-auto sm:block"
        />
        <h1 className="font-montecarlo text-5xl mx-auto text-white drop-shadow-md w-fit">
          Zen Cut
        </h1>
        <ul className="text-white flex flex-col mt-6 text-lg">
          <Link
            to={"/admin/products"}
            className={
              "w-fit py-2 px-4  flex items-center gap-x-3" +
              (pageName === "services" ? " font-semibold" : " font-medium")
            }
            onClick={() => setToggleNavBar(false)}
          >
            <img src={box} className="w-5" />
            Produse
          </Link>
          {/* <Link
            to={"/admin/program"}
            className={
              "w-fit py-2 px-4 font-medium flex items-center gap-x-3 " +
              (pageName === "categories" ? "font-semibold" : "font-medium")
            }
            onClick={() => setToggleNavBar(false)}
          >
            <img src={clock_light} className="w-5" />
            Program de lucru
          </Link>
          <Link
            to={"/admin/appointments"}
            className={
              "w-fit py-2 px-4  flex items-center gap-x-3" +
              (pageName === "services" ? " font-semibold" : " font-medium")
            }
            onClick={() => setToggleNavBar(false)}
          >
            <img src={calendar} className="w-5" />
            Programări
          </Link> */}
        </ul>
      </div>
      <div className="w-[calc(100%-12rem)] md:w-full sm:w-full">
        <div className="w-full h-10 md:h-12 bg-dark flex items-center px-6 text-white uppercase font-semibold text-lg">
          <img
            onClick={() => setToggleNavBar(!toggleNavBar)}
            src={burger}
            className="w-8 cursor-pointer mr-6 my-2 hidden md:block sm:block"
          />
          <div className="flex justify-between w-full">
            {pageName}
            <img
              src={exit}
              className="w-6 ml-auto cursor-pointer"
              onClick={() => {
                localStorage.removeItem("code");
                setAccessGranted(false);
              }}
            />
          </div>
        </div>
        <div className="p-8 sm:p-4 relative min-h-[calc(100vh-2.5rem)]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
