import React, { useState, useEffect } from "react";
import axios from "axios";
import api_url from "../constants";
import { LineWave } from "react-loader-spinner";
import confirmation from "../static/images/confirmation.svg";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const CancelAppointment = () => {
  const { id, cancel_code } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [successfullyCancelled, setSuccessfullyCancelled] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    axios.post(`${api_url}/appointment/${id}/cancel/`, { cancel_code }).then(
      (response) => {
        setIsLoading(false);
        setSuccessfullyCancelled(true);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
        setError(error.request.response.replace('"', "").replace('"', ""));
      }
    );
  }, []);

  return (
    <div>
      <Helmet>
        <title>Anulare programare</title>
      </Helmet>
      {isLoading && (
        <div className="flex justify-center items-center h-screen">
          <LineWave color="#1f2937" size={100} />
        </div>
      )}
      {!isLoading && successfullyCancelled && (
        
        <div className="flex flex-col justify-center items-center h-screen">
          <h1 className="text-5xl font-bold text-center font-montecarlo">
            Zen Cut
          </h1>
          <p className="text-center mt-4">
            Programarea a fost anulată cu succes. Vă mulțumim!
          </p>
          <Link to="/" className="mt-4 hover:underline">
            Înapoi la pagina principală
          </Link>
        </div>
      )}
      {!isLoading && !successfullyCancelled && (
        <div className="flex flex-col justify-center items-center h-screen">
          <h1 className="text-5xl font-bold text-center font-montecarlo">
            Zen Cut
          </h1>
          <p className="text-center font-semibold mt-4">{error}</p>
          <Link to="/" className="mt-4 hover:underline">
            Înapoi la pagina principală
          </Link>
        </div>
      )}
    </div>
  );
};

export default CancelAppointment;
