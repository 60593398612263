import React from "react";
import Home from "./screens/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLayout from "./screens/AdminLayout";
import AdminAppointments from "./screens/AdminAppointments";
import AdminProgram from "./screens/AdminProgram";
import AdminProducts from "./screens/AdminProducts";
import CancelAppointment from "./screens/CancelAppointment";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route
          path="/cancel/:id/:cancel_code"
          element={<CancelAppointment />}
        ></Route>
        <Route path="/admin" element={<AdminLayout />}>
          {/* <Route path="/admin/appointments" element={<AdminAppointments />} />
          <Route path="/admin/program" element={<AdminProgram />} /> */}
          <Route path="/admin/products" element={<AdminProducts />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
